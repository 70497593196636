import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NotificationsService } from 'app/layout/components/navbar/navbar-notification/notifications.service';
import { ToastrService } from 'ngx-toastr';

// Interface
interface notification {
  messages: [];
  systemMessages: [];
  system: Boolean;
}

@Component({
  selector: 'app-navbar-notification',
  templateUrl: './navbar-notification.component.html'
})
export class NavbarNotificationComponent implements OnInit {
  // Public
  public notifications: notification;
  count: any;

  /**
   *
   * @param {NotificationsService} _notificationsService
   */
  constructor(private _notificationsService: NotificationsService, private toastr:ToastrService, private router:Router) {}

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit(): void {
    // this._notificationsService.onApiDataChange.subscribe(res => {
    //   this.notifications = res;
    // });
    if (localStorage.getItem('userData')) {
      this.getAllNotifications();
    }
  }

  getAllNotifications() {
    this._notificationsService.getAllNotifications().subscribe((res) => {
      this.notifications = res.data;
      this.count = res.count;
    },
      (error) => {
      });
  }

  read(id:any, is_read:any, route:any, message:any, user_id:any){
    if(is_read == false){
      this._notificationsService.readNotification(id).subscribe((res) => {
        this.getAllNotifications();
        this.toastr.success('Success', 'Notification read', {
          timeOut: 3000
        });
      },
        (error) => {
        });
    }
    let routeArr = route.split('?');
    console.log(routeArr, "Length");
    
    if(routeArr.length == 2){
      console.log("For Vendor Form");
    this.router.navigate([`${routeArr[0]}`], { queryParams: { vendorId: user_id, requestId:message.slice(0,8) } });
    } else 
    if(routeArr.length == 3){
      console.log("For Source Manager");
    this.router.navigate([`${routeArr[0]}`], { queryParams: { vendorId: user_id, requestId:message.slice(0,8) } });
    } else {
      this.router.navigate([`${routeArr[0]}`]);
    }
    
  }
}
