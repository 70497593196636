import { CoreMenu } from '@core/types';

//? DOC: http://localhost:7777/demo/vuexy-angular-admin-dashboard-template/documentation/guide/development/navigation-menus.html#interface

function isAllowed(moduleName) {
  if (localStorage.getItem('menuPermissions')) {
    if (JSON.parse(localStorage.getItem('menuPermissions')).includes(moduleName)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}

function isAllowedParent(moduleName) {
  if (localStorage.getItem('menuPermissionsParent')) {
    if (JSON.parse(localStorage.getItem('menuPermissionsParent')).includes(moduleName)) {
      return false
    } else {
      return true
    }
  } else {
    return true
  }
}
export const menu = [
  {
    id: 'dashboard/home',
    title: 'Home',
    type: 'item',
    icon: 'package',
    url: 'dashboard/home',
    hidden: isAllowed('home')
  },
  {
    id: 'apps/requests/requests-form',
    title: 'Generate AQ',
    type: 'item',
    icon: 'package',
    url: 'apps/requests/requests-form',
    hidden: isAllowed('main form')
  },
  {
    id: 'apps/requests/requests-list',
    title: 'Assessment',
    type: 'item',
    icon: 'package',
    url: 'apps/requests/requests-list',
    hidden: isAllowed('requests')
  },
  {
    id: 'apps/master/category-pending',
    title: 'Category Approval',
    type: 'item',
    icon: 'package',
    url: 'apps/master/category-pending',
    hidden: isAllowed('category approval')
  },
  {
    id: 'apps/master/reason-pending',
    title: 'Reason Approval',
    type: 'item',
    icon: 'package',
    url: 'apps/master/reason-pending',
    hidden: isAllowed('reason approval')
  },
  {
    id: 'apps/vendors/my-requests',
    title: 'Assessment',
    type: 'item',
    icon: 'package',
    url: 'apps/vendors/my-requests',
    hidden: isAllowed('my requests')
  },
  // {
  //   id: 'TRANSACTIONS',
  //   type: 'section',
  //   title: 'Home',
  //   hidden: isAllowedParent('transactions'),
  //   icon: 'package',
  //   children: [
  //     {
  //       id: 'form',
  //       title: 'Generate AQ',
  //       type: 'item',
  //       icon: 'file-text',
  //       url: 'apps/requests/requests-form',
  //       hidden: isAllowed('main form'),
  //     },
  //     {
  //       id: 'requests',
  //       title: 'Requests',
  //       type: 'item',
  //       icon: 'message-square',
  //       url: 'apps/requests/requests-list',
  //       hidden: isAllowed('requests'),
  //     },
  //     {
  //       id: 'comparison-report',
  //       title: 'Comparison Report',
  //       type: 'item',
  //       icon: 'file-text',
  //       url: 'apps/requests/comparison-report',
  //       hidden: isAllowed('comparison report'),
  //     }
  //   ]
  // },
  // {
  //   id: 'Assessment',
  //   type: 'section',
  //   title: 'Assessment',
  //   // translate: 'MENU.APPS.SECTION',
  //   icon: 'package',
  //   hidden: isAllowedParent('vendors'),
  //   children: [
  //     // {
  //     //   id: 'all-forms',
  //     //   title: 'All Forms',
  //     //   // translate: 'MENU.APPS.Main Form',
  //     //   type: 'item',
  //     //   icon: 'copy',
  //     //   url: 'apps/vendors/all-forms',
  //     //   hidden: isAllowed('all forms'),
  //     // },
  //     {
  //       id: 'my-requests',
  //       title: 'My Requests',
  //       // translate: 'MENU.APPS.Main Form',
  //       type: 'item',
  //       icon: 'message-square',
  //       url: 'apps/vendors/my-requests',
  //       hidden: isAllowed('my requests'),
  //     }
  //   ]
  // },
  {
    id: 'MASTERS',
    type: 'section',
    title: 'Masters',
    // translate: 'MENU.APPS.SECTION',
    icon: 'package',
    hidden: isAllowedParent('masters'),
    children: [
      {
        id: 'users',
        title: 'User Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'user',
        url: 'apps/master/user',
        hidden: isAllowed('user master'),
      },
      {
        id: 'roles',
        title: 'Role Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'users',
        url: 'apps/master/roles',
        hidden: isAllowed('role master'),
      },
      {
        id: 'category',
        title: 'Category Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'grid',
        url: 'apps/master/category',
        hidden: isAllowed('category master'),
      },
      {
        id: 'reason',
        title: 'Reason Master',
        // translate: 'MENU.APPS.Main Form',
        type: 'item',
        icon: 'rotate-cw',
        url: 'apps/master/reason',
        hidden: isAllowed('reason master'),
      },
    ]
  },
 
];


