<li ngbDropdown class="nav-item dropdown-notification mr-25">
  <a class="nav-link" ngbDropdownToggle id="navbarNotificationDropdown"
    ><i class="ficon" data-feather="bell"></i
    ><span class="badge badge-pill badge-danger badge-up">{{count}}</span></a
  >
  <ul
    ngbDropdownMenu
    aria-labelledby="navbarNotificationDropdown"
    class="dropdown-menu dropdown-menu-media dropdown-menu-right"
  >
    <!-- Notifications header -->
    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">Notifications</h4>
        <div class="badge badge-pill badge-light-primary">
          {{count}}
        </div>
      </div>
    </li>
    <!--/ Notifications header -->

    <!-- Notifications content -->
    <li class="scrollable-container media-list" [perfectScrollbar]>
      <a class="d-flex" href="javascript:void(0)" *ngFor="let message of notifications" (click)="read(message?.id, message?.is_read, message?.route, message?.notification, message?.user_id)">
        <div class="media d-flex align-items-start">
          <!-- <div class="media-left">
            <div class="avatar">
              <img [src]="message.image" alt="avatar" width="32" height="32" />
            </div>
          </div> -->
          <div class="media-body">
            <p class="media-heading" [innerHTML]="message.heading"></p>
            <a><small style="cursor: unset !important;" *ngIf="message?.is_read == true" class="notification-text">{{ message.notification }}</small></a>
            <a><small style="color: black !important;" *ngIf="message?.is_read == false" class="notification-text"><strong>{{ message.notification }}</strong></small></a>
          </div>
        </div></a
      >
    </li>
    <!--/ Notifications content -->

  </ul>
</li>
